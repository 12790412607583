<template>
  <div>
    <v-form
      ref="form"
      @submit.prevent="onSubmit()">
      <v-card
        class="px-3 py-5 ma-4"
        outlined>
        <v-row class="card-header">
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <h2>
                {{ $t('global.contact') }}
              </h2>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12">
            <v-text-field
              v-model="form.contactFirstname"
              :rules="notEmpytyRules"
              :label="`${$t('global.name')}`" />
            <v-text-field
              v-model="form.contactLastname"
              :rules="notEmpytyRules"
              :label="`${$t('global.lastname')}`" />
            <v-text-field
              v-model="form.contactTel"
              :rules="notEmpytyRules"
              :label="`${$t('global.tel')}`" />
            <v-text-field
              v-model="form.contactEmail"
              :rules="notEmpytyRules"
              :label="`${$t('global.email')}`" />
            <v-text-field
              v-model="form.organizationName"
              :rules="notEmpytyRules"
              :label="`${$t('global.organizationName')}`" />
            <v-row justify="center">
              <v-col
                cols="12"
                class="d-flex align-baseline justify-space-between pb-0">
                <p>{{ $t('booking.visitConsent') }}<br><span class="red--text">{{ $t('booking.expectPdf') }}</span></p>
                <v-btn
                  v-if="!file"
                  color="blue"
                  depressed
                  dark
                  text
                  class="px-0"
                  style="text-transform: none"
                  @click="handleFileImport()">
                  <!-- {{ $t('booking.visitConsent') }} -->
                  <span style="font-weight: 700">{{ $t('booking.attachFile') }}</span>
                  <v-icon
                    dark
                  >
                    mdi-paperclip
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="red"
                  depressed
                  dark
                  text
                  class="px-0"
                  style="text-transform: none"
                  @click="file = ''">
                  <!-- {{ $t('booking.visitConsent') }} -->
                  <span style="font-weight: 700">{{ $t('booking.clear') }}</span>
                  <v-icon
                    dark
                  >
                    mdi-bin
                  </v-icon>
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="application/pdf"

                  @change="onFileChanged($event)"
                />
              </v-col>
              <v-col
                cols="12"
                class="pt-0">
                <v-chip
                  v-if="file"
                >
                  <span class="text-truncate">{{ file.name }}</span>&nbsp;<span>({{ fileSize }})</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="card-header">
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <h2>
                {{ $t('booking.bookingDetail') }}
              </h2>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-space-between pb-0">
            <h4>{{ $t('booking.bookingDate') }}</h4>
            <p>{{ date }}</p>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-space-between pt-0">
            <h4>{{ $t('booking.bookingTime') }}</h4>
            <p>{{ formatSchedule(startTime, endTime) }}</p>
          </v-col>
          <v-col cols="12">
            <p> {{ $t('global.age') }}</p>
            <div
              v-for="range in participants"
              :key="range.text"
              class="d-flex justify-space-between align-baseline">
              <v-col
                cols="5"
                class="py-0 px-0 ml-2">
                <p>
                  {{ range.text }} {{ $t('global.year') }}
                </p>
              </v-col>
              <v-col
                cols="5"
                class="py-0 px-0">
                <v-text-field
                  v-model.number="range.count"
                  :rules="numberRules"
                  type="number"
                  :label="`${ $t('global.amount') }`"
                  :suffix="`${ $t('global.person') }`"
                ></v-text-field>
              </v-col>
            </div>
            <p class="text-center">
              {{ $t('global.allParticipants') }} : {{ allCount }} {{ $t('global.person') }}
            </p>
            <p
              v-if="checkMax"
              class="text-center red--text">
              {{ $t('booking.validateMax') }}
            </p>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="form.note"
              outlined
              :label="`${ $t('global.note') } (${ $t('global.foodAllergy') } ${ $t('global.or') } ${ $t('global.congenitalDisease') } )`"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-btn
          type="submit"
          :disabled="checkMax || allCount <= 0"
          class="white--text"
          color="#23b574"
          block>
          {{ $t('booking.book') }}
        </v-btn>
      </v-card>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import prettyBytes from 'pretty-bytes'

export default {
  props: {
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: 0
    },
    slotbooking: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isLoading: false,
      validate: null,
      form: {
        scheduleId: '',
        memberId: '',
        participants: null,
        branch: process.env.VUE_APP_BRANCH,
        organizationName: '',
        contactFirstname: '',
        contactLastname: '',
        contactTel: '',
        contactEmail: '',
        note: '',
        verifyNote: ''
      },
      file: null,
      participants: [
        {
          text: '1 - 5',
          range: '1-5',
          count: 0
        },
        {
          text: '6 - 12',
          range: '6-12',
          count: 0
        },
        {
          text: '13 - 18',
          range: '13-18',
          count: 0
        },
        {
          text: '19 - 24',
          range: '19-24',
          count: 0
        },
        {
          text: '25 - 59',
          range: '25-59',
          count: 0
        },
        {
          text: '60',
          range: '60',
          count: 0
        }
      ]
    }
  },
  computed: {
    notEmpytyRules () {
      return [
        (v) => !!v || this.$t('global.validate')
      ]
    },
    numberRules () {
      return [
        (v) => v >= 0 || this.$t('global.validate'),
        (v) => Number.isInteger(v) || this.$t('global.validate')
      ]
    },
    rangeItems () {
      return [
        { text: this.$t('global.rangeTimeItems.morning'), value: 'morning' },
        { text: this.$t('global.rangeTimeItems.afternoon'), value: 'afternoon' },
        { text: this.$t('global.rangeTimeItems.evening'), value: 'evening' },
        { text: this.$t('global.rangeTimeItems.allday'), value: 'allday' }
      ]
    },
    ...mapGetters({
      formStore: 'bookingGroup/form'
    }),
    allCount () {
      let count = 0
      this.participants.forEach((item) => {
        count += item.count
      })
      return Number(count)
    },
    checkMax () {
      if (this.allCount > (this.max - this.slotbooking)) {
        return true
      }
      return false
    },
    fileSize () {
      return prettyBytes(this.file.size)
    }
  },
  mounted () {
    this.form = this.formStore
  },
  methods: {
    ...mapActions({
      setGroup: 'booking/setGroup',
      setIsFromGroup: 'booking/setIsFromGroup'
    }),
    changeLanguage (type) {
      this.$i18n.locale = type
      this.isLoading = true
      this.$nextTick(() => {
        this.isLoading = false
      })
    },
    async onSubmit () {
      try {
        const valid = this.$refs.form.validate()
        if (valid) {
          this.form.participants = this.participants
          this.$emit('input', { form: this.form, file: this.file })
        }
      } catch (error) {
        console.error(error)
      }
    },
    onFileChanged (event) {
      if (event.target.files[0]) {
        this.file = event.target.files[0]
        this.$refs.uploader.value = ''
      } else {
        this.file = ''
      }
    },
    handleFileImport () {
      this.$refs.uploader.click()
    },
    formatSchedule (startTime, endTime) {
      if (startTime === '00:00' && endTime === '23:59') {
        return 'Allday'
      }
      return `${startTime} - ${endTime}`
    }
  }
}
</script>
